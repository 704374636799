import {appStoreIcon, googlePlayIcon,videoThumbnail} from '../../images'
import {motion} from "framer-motion"
import {ComingSoonPopup} from "../ComingSoonPopup";
import {useState} from "react";
import '../TypeWriter.css'
import IntroVideo from "../../video/WanderFlow-Intro-v2_x264.mp4";
import WanderInBtn from "./WanderInBtn";
import VerticalSlidingText from "../AnimatedText/VerticalSlidingText";

const iOSUrl = null;
const APKUrl = null;

const WANDERFLOW_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSdHf360WF_kstcsolsRpPuQeIHIN_bCquij5wMM_Zxz9moxJg/viewform?usp=sf_link'

// const videoOptions = {
//     playerVars: {
//         autoplay: 1,
//         controls: 0,
//         rel: 0,
//         showinfo: 0,
//         mute: 1,
//         loop: 1
//     }
// };

function wanderInBtnClickHandler(){
    window.location.href= WANDERFLOW_URL
}

function HeroVideo(){
    return (
        <div className="w-full flex flex-col h-full justify-center items-center px-0 py-6 sm:py-0">
            <video
                className={"rounded-xl w-full shadow-2xl"}
                id="comp-lk4jiey7_video"
                role="presentation"
                crossOrigin="anonymous"
                playsInline=""
                preload="auto"
                loop
                muted
                tabIndex="-1"
                controls={true}
                autoPlay={true}
                src={IntroVideo}
                poster={videoThumbnail}
            ></video>
        </div>
    )
}

export default function Hero(){
    const [showComingSoonPopup, setShowComingSoonPopup] = useState(false)

    function handleDownloadBtnClick(){
        console.log("download button clicked")
        setShowComingSoonPopup(true)

        setTimeout(() => {
            setShowComingSoonPopup(false)
        }, 1000) // hide the popup after 1 second
    }

    return(
        <div className="w-screen min-w-[330px] lg:h-hero mt-6 lg:m-0 px-3 lg:px-36 lg:mt-[-2rem] grid grid-cols-1 lg:grid-cols-2 lg:gap-6">
            <ComingSoonPopup show={showComingSoonPopup} />
            {/* video would be in front of texts in mobile view */}
            <div className={'only-mobile'}>
                <HeroVideo />
            </div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: 1}}
                className="flex flex-col justify-center items-start px-1 lg:px-6 py-3 gap-0 w-full">
                <div>
                    <div>
                        <p>
                            <mark>A "living and breathing" content platform</mark>
                        </p>
                        <h1
                            className={'w-full'}
                            // className={"blink"}
                        >
                            <VerticalSlidingText />
                        </h1>
                    </div>
                </div>
                <div className="flex flex-col gap-1">
                    <p>
                        {/*Easily enter <b>Flow State</b>, <mark>browse, learn, connect </mark>.*/}
                        Explore WanderFlow, where content breathes for you.<br className={'only-desktop'}/> Enter the <b>Flow State</b> now.
                    </p>
                </div>
                <WanderInBtn />
                <div
                    className="flex flex-col sm:flex-row gap-3 pt-6 justify-start"
                >
                    <img src={appStoreIcon} alt="app store icon" className="app-store-icon" onClick={handleDownloadBtnClick} />
                    <img src={googlePlayIcon} alt="google play icon" className="app-store-icon" onClick={handleDownloadBtnClick}/>
                </div>
            </motion.div>
            <div className={'only-desktop'}>
                <HeroVideo />
            </div>
        </div>
    )
}
import ContentSection from "./ContentSection";
import {
    iconCollection, iconContribution, iconFeedback,
    iconLoop,
    iconRichFormate,
    iconRight,
    iconStreamline,
    iconSystematize,
    recordGif1,
    content1,
    content2,
    content3,
} from "../../images";

const dateContents =[
    {
        id:1,
        image: content1,
        title: 'Embark on Your Unique Journey',
        subTitle1: 'Curate to your input',
        subTitle2: 'Interact to Reshape the Flow, constantly',
        text1: 'Pick and shape content that resonates with you. WanderFlow adapts to your needs and interests. Start the exploration, your way.\n',
        text2: 'Engage with a living content stream that evolves, tailor-made for your ever-changing needs and curiosities.',
        icon1: iconSystematize,
        icon2: iconLoop,
        flipped: false
    },
    {
        id:2,
        image: content2,
        title: 'Enhance Your Understanding',
        subTitle1: 'Complex Becomes Easy',
        subTitle2: 'Dull Becomes Interesting',
        text1: 'Break down intricate subjects into digestible insights that fit you. WanderFlow simplifies without losing depth.',
        text2: 'Revive mundane topics into compelling explorations. WanderFlow presents content in the way that ignites your interest',
        icon1: iconFeedback,
        icon2: iconRichFormate,
        flipped: true
    },
    {
        id:3,
        image: content3,
        title: 'Build Your Knowledge and Community',
        subTitle1: 'Crowdsourced Insights, De-Noised',
        subTitle2: 'Co-Create, Contribute, Build a Following',
        text1: 'Harvest collective wisdom, filtered for quality, relevance, and your unique preferences',
        text2: 'Collaborate and influence within a community of thinkers. Your voice matters here.',
        icon1: iconStreamline,
        icon2: iconContribution,
        flipped: false
    },
    // {
    //     id:4,
    //     image: recordGif1,
    //     title: 'Share your insights and influence others in the community',
    //     subTitle1: 'Contribute to Content',
    //     subTitle2: 'Provide Feedback',
    //     text1: 'Exchange thoughts, comment on, and build upon shared content',
    //     text2: 'Boost valuable insights with votes and collects, and more...',
    //     icon1: iconContribution,
    //     icon2: iconFeedback,
    //     flipped: true
    // }
]

export default function ContentSections() {
    return (
        <>
            {/*   */}
            <div className={'mt-12 sm:mt-0'}>
                {dateContents.map((content) => {
                    return (
                        <ContentSection
                            key={content.id}
                            {...content}
                        />
                    )
                })}
            </div>
        </>
    )
}
const colorFill = '#1529c3';

export default function RibbonBlock(props){
    const {isTop = true} = props;
    return (
        <>
            {isTop ? (
                <div className={'mt-12 mb-[-1px] p-0'}>
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 183">
                        <path fill={colorFill} d="m1920,183.33c-279,0-683.36-22.38-974.5-82.33C707.5,52,302,7,0,7c0,205.79,0-29.79,0,176"/>
                    </svg>
                </div>) : (
                <div className={'mt-[-1px] p-0'}>
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 1920 89">
                        <path transform="scale(1,0.75)" fill={colorFill} d="m0,.84c279,0,683.36,11.19,974.5,41.16,238,24.5,643.5,47,945.5,47,0-102.9,0,14.9,0-88"/>
                    </svg>
                </div>
            )}
        </>
    )
}
import {logo3} from "../images";
import WanderInBtn from "./home/WanderInBtn";

export default function Footer(){
    return(
        <>
        <div className={'border-t border-t-deepBlue px-12 sm:pl-24 sm:pr-48 m-0 bg-deepBlue grid grid-cols-1 sm:grid-cols-[1fr_3fr] gap-3'}>
            <div className={'w-full sm:h-full flex flex-col justify-end items-center pt-3 sm:pt-0 pb-0 sm:pb-9'}>
                {/* logo */}
                <img className="w-12 sm:w-60" src={logo3} alt={"logo3"} />
                <span className="h-6 font-bold text-md sm:text-lg text-white">
                        WANDERFLOW
                </span>
            </div>
            <div className={'w-full sm:h-full flex flex-col justify-end items-start text-white pb-9 gap-3'}>
                <h2 className={'text-left px-0'}>WanderFlow is where content doesn't just inform</h2>
                <span className={'only-desktop'}>it lives, breathes, and evolves with you. Whether you're looking to understand the world, systematize your knowledge, or engage with a community of thinkers, WanderFlow is your personalized pathway.</span>
                <h3 className={'only-mobile'}>it lives, breathes, and evolves with you. Whether you're looking to understand the world, systematize your knowledge, or engage with a community of thinkers, WanderFlow is your personalized pathway.</h3>
                <WanderInBtn />
            </div>
        </div>
        <div className="w-screen text-xs sm:text-md mt-0 h-15 bg-midBlue flex justify-center items-center">
            <p className="text-white text-sm">© {new Date().getFullYear()}  WanderFlow. All rights reserved.</p>
        </div>
        </>
    )
}
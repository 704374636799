import Hero from "../components/home/Hero";
import Ribbon from "../components/home/Ribbon";
import ContentSection from "../components/home/ContentSection";
import {
    recordGif1,
    iconRight,
    iconLoop,
    iconStreamline,
    iconRichFormate,
    iconCollection,
    iconSystematize,
    iconContribution,
    iconFeedback,
    logo2,
} from "../images";
import WanderInBtn from "../components/home/WanderInBtn";
import ContentSections from "../components/home/ContentSections";
import RibbonBlock from "../components/home/RibbonBlock";

export default function Home(){
    return(
        <div>
            <Hero />
            <RibbonBlock />
            <div className={'bg-deepBlue w-full flex flex-col justify-center items-center text-white pt-3 sm:pt-9 pb-6 sm:pb-24 px-6'}>
                <h2>Wander<span className={'text-midGreen'}>Flow</span> is <br className={'only-desktop'}/>
                    the content platform of the AI 2.0 Era</h2>
            </div>
            <RibbonBlock isTop={false}/>
            <ContentSections />
            <RibbonBlock />
        </div>
    )
}
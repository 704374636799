import React from 'react'
import './VerticalSliding.css'

export default function VerticalSlidingText(props) {
    return (
        <>
            <div className={'slidingVertical'}>
                <span><mark>R</mark>egenerates<br />
                    to <mark>s</mark>uit <mark>y</mark>ou</span>
                <span className={'text-[0.95em]'}><mark>E</mark>xplains<br />
                    your <mark>q</mark>uestions</span>
                <span className={'text-[0.85em]'}><mark>C</mark>ontextualizes<br />
                    with <mark>b</mark>ackground</span>
                <span className={'text-[0.90em]'}><mark>I</mark>llustrates<br />
                    with <mark>e</mark>xamples</span>
                <span><mark>A</mark>rticulates<br />
                    to <mark>f</mark>it <mark>y</mark>ou</span>
            </div>
        </>

    )
}
const WANDERFLOW_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSdHf360WF_kstcsolsRpPuQeIHIN_bCquij5wMM_Zxz9moxJg/viewform?usp=sf_link'

function wanderInBtnClickHandler(){
    window.location.href= WANDERFLOW_URL
}

export default function WanderInBtn() {
    return (
        <div>
            <button className="btn mb-0" onClick={wanderInBtnClickHandler}>Wander In</button>
        </div>
    )
}
import {logo,logo2,logo3} from '../images'
import {Link, NavLink} from "react-router-dom";

const LINKS = [
    {name: 'Home', link: '/'},
    {name: 'About', link: '/about'},
    {name: 'login', link: '/login'}
]

const WANDERFLOW_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSdHf360WF_kstcsolsRpPuQeIHIN_bCquij5wMM_Zxz9moxJg/viewform?usp=sf_link'

const enterBtnClickHandler = () => {
    window.location.href= WANDERFLOW_URL
}

export default function Navbar(){
    return(
        <div className="flex flex-row justify-between items-center px-3 sm:px-24 py-3">
            <div className="flex flex-row justify-center items-center">
                <div>
                    {/* logo */}
                    <img className="w-12" src={logo2} alt={"logo"} />
                </div>
                <div className="flex flex-col justify-start items-start px-3 gap-0">
                    <span className="font-bold text-md sm:text-xl">
                        WANDERFLOW
                    </span>
                    {/*<span className="hidden sm:block">*/}
                    {/*    Communicate. Collaborate. Create.*/}
                    {/*</span>*/}
                </div>
            </div>
            <div>
                {/*<button*/}
                {/*    onClick = {enterBtnClickHandler}*/}
                {/*    className="btn mr-3 sm:mr-0">Enter</button>*/}
            </div>
        </div>
    )
}

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import {Home, About, ErrorPage, Login} from "./pages";
import "./App.css"
import BackToTop from "./components/BackToTop";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <BackToTop />
      <Routes>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="login" element={<Login />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;

import ClickableImage from "../ClickableImage";

export default function ContentSection(props){
    const {image, title = 'enjoy', subTitle1, subTitle2, text1, text2, icon1, icon2, flipped = false} = props;
    return (
        <>
            {/* desktop view, which will be influenced by flipped var */}
            {flipped ? (
                    <div className={"hidden sm:block"}>
                        <div className="w-full h-128 px-60 grid grid-cols-[2fr_1fr]">
                            <ContentDescription title={title} subTitle1={subTitle1} subTitle2={subTitle2} text1={text1} text2={text2} icon1={icon1} icon2={icon2} />
                            <ContentMainImage image={image} />
                        </div>
                    </div>
                ) : (
                    <div className={"hidden sm:block"}>
                        <div className="w-full h-128 px-60 grid grid-cols-[1fr_2fr]">
                            <ContentMainImage image={image} />
                            <ContentDescription title={title} subTitle1={subTitle1} subTitle2={subTitle2} text1={text1} text2={text2} icon1={icon1} icon2={icon2} />
                        </div>
                    </div>
                )}
            {/* mobile view, which will not be influenced by flipped var */}
            <div className={"block my-6 sm:hidden"}>
                <div className="w-full flex flex-col justify-center items-center">
                    <ContentDescription image={image} title={title} subTitle1={subTitle1} subTitle2={subTitle2} text1={text1} text2={text2} icon1={icon1} icon2={icon2} />
                </div>
            </div>
        </>
    )
}

function ContentMainImage({image}){
    return(
        <div className={'w-full sm:h-full px-24 sm:px-12 py-0 sm:py-6'}>
            <ClickableImage
                src={image}
                alt='content main image'
            />
        </div>
    )
}

function ContentDescription(props){
    const {image, title = 'enjoy', subTitle1, subTitle2, text1, text2, icon1, icon2} = props;
    return(
        <>
            {/* desktop view */}
            <div className={"w-full h-full only-desktop"}>
                <div className={"w-full h-full px-12 sm:py-24 flex flex-col justify-between gap-3"}>
                    {/*<h2 className={'py-3'}>{title}</h2>*/}
                    {/*<div className={"h-full grid grid-cols-2 gap-6"}>*/}
                    {/*    <div className={"flex flex-col gap-2"}>*/}
                    {/*        <img className={'w-12 h-12 cover'} src={icon1} alt='content icon1'></img>*/}
                    {/*        <h4 className={'text-deepBlue'}>{subTitle1}</h4>*/}
                    {/*        <p>{text1}</p>*/}
                    {/*    </div>*/}
                    {/*    <div className={"flex flex-col gap-2"}>*/}
                    {/*        <img className={'w-12 h-12 cover'} src={icon2} alt='content icon2'></img>*/}
                    {/*        <h4 className={'text-deepBlue'}>{subTitle2}</h4>*/}
                    {/*        <p>{text2}</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={"pb-12"}>
                        <h2>{title}</h2>
                    </div>
                    <div className={"w-full grid grid-cols-2 gap-6"}>
                        <img className={'w-12 cover'} src={icon1} alt='content icon1'></img>
                        <img className={'w-12 cover'} src={icon2} alt='content icon2'></img>
                    </div>
                    <div className={"w-full grid grid-cols-2 gap-6"}>
                        <h4 className={'text-deepBlue'}>{subTitle1}</h4>
                        <h4 className={'text-deepBlue'}>{subTitle2}</h4>
                    </div>
                    <div className={"w-full grid grid-cols-2 gap-6"}>
                        <p>{text1}</p>
                        <p>{text2}</p>
                    </div>
                </div>
            </div>
            {/* mobile view */}
            <div className={"block sm:hidden my-3"}>
                <h2>{title}</h2>
                <ContentMainImage image={image} />
                <div className={"px-12 py-6 flex flex-col gap-3"}>
                    <div className={"flex flex-col gap-2"}>
                        <div className={"flex flex-row gap-3 items-center"}>
                            <img className={'w-12 h-12 cover'} src={icon1} alt='content icon1'></img>
                            <h3 className={'text-deepBlue'}>{subTitle1}</h3>
                        </div>
                        <div>
                            <p>{text1}</p>
                        </div>
                    </div>
                    <div className={"flex flex-col gap-2"}>
                        <div className={"flex flex-row gap-3 items-center"}>
                            <img className={'w-12 h-12 cover'} src={icon2} alt='content icon2'></img>
                            <h3 className={'text-deepBlue'}>{subTitle2}</h3>
                        </div>
                        <div>
                            <p>{text2}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}